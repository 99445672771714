// Flex
@mixin display($flex) {
  display: -ms-flexbox;
  display: flex;
}
@mixin flex-wrap($value) {
  -ms-flex-wrap: $value;
      flex-wrap: $value;
}
@mixin flex-direction($value) {
  -ms-flex-direction: $value;
      flex-direction: $value;
}
@mixin align-content($value) {
  -ms-flex-line-pack: $value;
       align-content: $value;
}
@mixin align-items($value) {
  -ms-flex-align: $value;
     align-items: $value;
}

// Position
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}