/* -----------------------------------------------------------------
    - Intro
----------------------------------------------------------------- */
.intro-home {
	background-color: #314F6F;
	color: $white-color;
	height: 600px;
	position: relative;

	.intro__container {
		height: 100%;
	}

	&__container {
		display: flex;
		justify-content: center;
	}

	.infoContact {
		margin-top: 20px;
		text-align: center;
		font-size: 1.125rem;
    font-weight: 600;
		a {
			color: #fff;
		}
	}
	.title {
		font-size: 30px;
		text-align: center;
	}

	img {
		max-width: 250px;
    margin: auto;
    display: flex;
	}
}

.intro {
  background-color: $gray-100-color;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white-color;
  height: 100vh;
  position: relative;
  
  &__bg-wrap {
    @include position(absolute, $top: 0, $left: 0);
	height: 100%;
	overflow: hidden;
    width: 100%;
  }
  
  &__bg {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  &__container {
    height: calc(100% - 8rem);
	position: relative;
	z-index: 2;
	
	@media only screen and (max-width: $large) {
	  height: calc(100% - 12rem);
	}
	
	@media only screen and (max-width: $medium) {
	  height: 100%;
	}	
  }
    
  // Button play
  &__btn-play {
    background-color: transparent;
	border: 0;
    color: $white-color;
	font-size: 1.125rem;
	font-weight: $weight-600;
	display: inline-flex;
    @include align-items(center);
	
	&:focus {
	  outline: none;
	}
	
	@media only screen and (max-width: $small) {
	  font-size: 1rem;
	}
	
	.btn-play {
	  margin-left: 1.5rem;
	  
	  @media only screen and (max-width: $small) {
	    margin-left: 1rem;
	  }
	}
  }
}


/* -----------------------------------------------------------------
    - Bottom panel / Booking panel
----------------------------------------------------------------- */

.bottom-panel {
  color: $black-color;
  height: 8rem;
  margin-top: -8rem;
  position: relative;
  width: 100%;
  z-index: 1;
	
  @media only screen and (max-width: $large) {
	background-color: $white-color;
	border-radius: 16px 16px 0 0;
  }
  
  @media only screen and (max-width: $small) {
    border-radius: 18px;
    margin-top: -18px;
  }
  
  // form
  &__wrap {
    background-color: $white-color;
  	//@include display(flex);
	@include align-items(center);
	justify-content: space-between;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	
	&::before {
	  content: '';
	  background-color: $white-color;
	  height: 100%;
	  @include position(absolute, $top: 0, $left: -100%);
	  width: 100%;
	  z-index: 0;
	  
	  @media only screen and (max-width: $large) {
	    display: none;
	  }
	}	
	
	@media only screen and (max-width: $large) {
	  @include flex-direction(column);
	  @include align-items(normal);
	  justify-content: center;
	  padding-top: 1rem;
	  padding-bottom: 1rem;
	}
	
	@media only screen and (max-width: $medium) {
	  padding-top: 2.8rem;
	  padding-bottom: 0;
	}
	
	@media only screen and (max-width: $small) {
	  background-color: transparent;
	  padding-top: 15px;
	  padding-right: 15px;
      padding-left: 15px;
	}
	
    @media only screen and (min-width: $small) {
      max-width: 510px;
    }

    @media only screen and (min-width: $medium) {
      max-width: 690px;
    }

    @media only screen and (min-width: $large) {
      max-width: 930px;
    }

    @media only screen and (min-width: $large-xl) {
      max-width: 1110px;
    }
	
    @media only screen and (min-width: $large-xxl) {
      max-width: 1210px;
    }
	
	.form-group {
	  margin-bottom: 0;
	  
	  @media only screen and (max-width: $large) {
	    margin-bottom: 1.3rem;
	  }
	  
	  @media only screen and (max-width: $large) {
	    margin-bottom: 1rem;
	  }	  
	  
	  @media only screen and (max-width: $small) {
	    margin-bottom: .6rem;
	  }
	}
	
	.inputFeature {
	  width: 10.6rem;
	  
	  @-moz-document url-prefix() {
        width: 11rem;
      }
	  
	  @media only screen and (max-width: $large) {
	    width: 100%;
	  }
	}
  }

  &__form-wrap {
	@media only screen and (min-width: $medium) {
	  @include align-items(center);
	}
	  
	@media only screen and (min-width: $large) {
	  flex-grow: 1;
	  margin-right: 0;
	}
	
	// IE
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	  @media only screen and (max-width: $large) {
	    width: calc(100% + 30px);
	  }
	}
  }
  
  .col-date-to {
    @media only screen and (max-width: $large) {
	  padding-right: 12px;
	}
	
    @media only screen and (max-width: $small) {
	  padding-right: 15px;
	}	
  }
  
  .col-date-from {
    @media only screen and (max-width: $large) {
	  padding-left: 12px;
	  padding-right: 9px;
	}
	
    @media only screen and (max-width: $medium) {
	  padding-right: 15px;
	}

    @media only screen and (max-width: $small) {
	  padding-left: 15px;
	}	
  }  
  
  // btn
  .btn-booking {
	@media only screen and (max-width: $large) {
	  margin-right: 0;
	  margin-top: .2rem;
	}
	
	@media only screen and (max-width: $medium) {
	  margin-top: .6rem;
	}	
	
	@media only screen and (max-width: $small) {
	  margin-top: .4rem;
	}	
  }
  
  #dropdownPersons {
    min-width: 20rem;
	
	@media only screen and (max-width: $large) {
	  margin-right: 15px;
	}
	
	@media only screen and (max-width: $medium) {
	  width: calc(100% - 30px);
	  margin-left: 15px;
	}
	
	@media only screen and (max-width: $medium) {
	  min-width: auto;
	}
  }
  
  #person-total {
    width: 11rem;
  
    @media only screen and (max-width: $large) {
	  width: 100%;
    }
  }
  
  // Slash
  .slash::before {
    right: 22%;
	
    @media only screen and (max-width: $large-xxl) {
	  right: 18%;
	}
	
    @media only screen and (max-width: $large-xl) {
	  display: none;
	}	
  }
  
  // Info
  &-title {
    font-size: 1.125rem;
	font-weight: $weight-600;
	
	@media only screen and (max-width: $small) {
	  font-size: 1rem;
	}
  }
  
  &-info {
    color: $gray-300-color;
	
	@media only screen and (max-width: $small) {
	  font-size: .875remrem;
	}
  }  
  
  // Booking panel floating
  &--floating {
    background-color: transparent;
	
	@media only screen and (max-width: $large) {
	  height: 12rem;
	  margin-top: -12rem;
    }
	
	@media only screen and (max-width: $medium) {
	  height: auto;
	  margin-top: -10rem;
    }
    
	@media only screen and (max-width: $small) {
	  background-color: $white-color;
	  box-shadow: 0 32px 64px 0 rgba($black-color,.05);
	  margin-top: -18px;
	  padding-bottom: 1rem;
	}
	
    .bottom-panel__wrap {
	  border-radius: 8px;
	  box-shadow: 0px 0px 80px rgba($gray-200-color,.3);
	  padding-left: 3rem;
	  
	  @media only screen and (max-width: $large-xl) {
	    padding-left: 2.5rem;
	  }
	  
	  @media only screen and (max-width: $large) {
	    padding-left: 1.875rem;
		padding-right: 1.875rem;
	  }

	  @media only screen and (max-width: $medium) {
	    padding-top: 1.7rem;
        padding-bottom: 1.875rem;
	  }
	  
	  @media only screen and (max-width: $small) {
	    box-shadow: none;
		padding: 15px 15px 0;
	  }
	}
	
    .bottom-panel__wrap::before {
	  display: none;
	}
	
	.btn-booking {
	  border-radius: 0 8px 8px 32px;
	  
	  @media only screen and (max-width: $large) {
	    border-radius: 5rem;
	  }
	}
  }  
}

.booking-panel {
  @media only screen and (max-width: $large) {
	background-color: $white-color;
	height: 12rem;
	margin-top: -12rem;
  }
  
  @media only screen and (max-width: $medium) {
	height: auto;
	margin-top: 0;
  }
  
  @media only screen and (max-width: $small) {
    border-radius: 18px;
	box-shadow: 0 32px 64px 0 rgba($black-color,.05);
    margin-top: -18px;
	padding-bottom: 1rem;
  }
}

/* Room details */
.bottom-panelRoom {
  @media only screen and (max-width: $small) {
	box-shadow: none;
    height: auto;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
  }
  
  .bottom-panel__wrap {
    @media only screen and (max-width: $medium) {
	  padding-top: 0;
	}
  }
}

.room-details { 
  @media only screen and (max-width: $small) {
    margin-left: 0;
	margin-right: 0;
  }
  
  &__item {
    @include display(flex);
	@include align-items(center);
	line-height: 1;
	
	@media only screen and (max-width: $small) {
	  font-size: .75rem;
	  justify-content: center;
	  padding-left: 0;
	  padding-right: 0;
	  
	  span {
	    display: none;
	  }
	}

	.bath-shower{
		.icon{
			margin-bottom: 5px;
		}
	}
	  
	[class^="icon-"] {
	  font-size: 1.5rem;
	  margin-right: 1rem;
	  
	  @media only screen and (max-width: $small) {
	    font-size: 1.1rem;
        margin-right: .6rem;
	  }
	}
  }
  
  &__item.slash::before {
    @media only screen and (max-width: $large-xl) {
	  display: block;
	}
	
	@media only screen and (max-width: $medium) {
	  display: none;
	}
  }
}


/* -----------------------------------------------------------------
    - Lightbox video
----------------------------------------------------------------- */

.lightbox-backdrop {
  background: rgba($white-color,0);
  display: none;
  height: 100%;
  @include position(fixed, $top: 0, $left: 0);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  z-index: 100;
}

.lightbox-content {
  @include display(flex);
  @include align-items(center);
  justify-content: center;
  height: 100%;
  width: 100%;
}

.video-foreground {
  position: relative;
  height: 80%;
  width: 70%;
  
  @media only screen and (max-width: $large-xxl) {
    height: 80%;
    width: 90%;
  }
  
  @media only screen and (max-width: $large) {
    height: 70%;
    width: 90%;
  }
  
  @media only screen and (max-width: $small) {
    height: 40%;
	margin-left: 15px;
	margin-right: 15px;
    width: 100%;
  }
  
  & iframe {
    @include position(absolute, $top: 0, $left: 0);
    width: 100%;
    height: 100%;
  }
}

.close-popup {
  border: 1px solid rgba($gray-100-color,.8);
  border-radius: 50%;
  color: $gray-300-color;
  cursor:pointer;
  font-size: 1.1rem;
  line-height: 2.3rem;
  @include position(absolute, $top: 1rem, $right: 1rem);
  text-align: center;
  height: 2.5rem;
  width: 2.5rem;
  transition: all .3s ease-in-out;
  
  &:hover {
    background-color: rgba($gray-100-color,.8);
	box-shadow: 0 0 0 5px rgba($gray-100-color,.8);
  }
  
  @media only screen and (max-width: $small) {
    font-size: .8rem;
    line-height: 1.4rem;
    height: 1.6rem;
    width: 1.6rem;
  }
}


/* -----------------------------------------------------------------
    - Intro Slider
----------------------------------------------------------------- */

.coverSlider {
  background-size: cover;
  background-position: center center;
  height: 100%;
  @include position(absolute, $top: 0, $left: 0);
  width: 100%;
}

.intro-slider {
  background-color: $gray-100-color;
  color: $white-color;
  height: 100vh;
  position: relative;
  z-index: 0;
  -webkit-overflow-scrolling: touch;

  .scaleCover {
	width: 100%;
	height: 100vh;
	transform: scale(1.1);
	transition: transform 1.0s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
	
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
	.scaleCover {
	  transform: scale(1);
	}
  }
  
  // Caption
  &__caption {
    color: $white-color;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	z-index: 2;  

	.title {
	  font-size: 9rem;
      margin-bottom: 0;
	  line-height: 1.06;
	  
	  @media only screen and (max-width: $large-xxl) {
	    font-size: 7.85vw;
	  }
	  
	  @media only screen and (max-width: $large) {
	    font-size: 4rem;
	  }
	  
	  @media only screen and (max-width: $medium) {
	    font-size: 3.8rem;
	  }	 

	  @media only screen and (max-width: $small) {
	    font-size: 2.5rem;
	  }	  
	}
	
    .description {
	  font-size: 1.125rem;
	  margin: .8rem auto 0;
	  width: 50%;
	  
	  @media only screen and (max-width: $large) {
	    font-size: 1rem;
	    width: 70%;
	  }

	  @media only screen and (max-width: $medium) {
	    width: 100%;
	  }
    }
  }
  
  // Image
  &__image {
    height: 100%;
    overflow: hidden;
	@include position(absolute, $top: 0, $left: 0);
	width: 100%;
	
	@media only screen and (max-width: $large) {
      width: 100%;
	  order: 1;
	}
	
	&.overlay {
	  position: absolute;
	}
	
	&.overlay::before {
      z-index: 2;
    }
	
	.swiper-slide {
	  overflow: hidden;
    }
  }

  // Navigation
  .slider-navigation {
    @include display(flex);
	margin: auto;
	@include position(absolute, $left: 0, $bottom: 10rem, $right: 0);
	z-index: 2;
	
	@media only screen and (max-width: $large) {
	  display: none;
	}
  }
  
  .slider-prev,
  .slider-next {
    color: $white-color;
	cursor: pointer;
    border: 0;
	background-color: transparent;
	font-size: 1.75rem;
	transition: all .4s ease-in-out;
	
	&:hover {
	  color: rgba($white-color,.6);
	}
	
	&:focus {
	  outline: none;
	}
  }
  
  .slider-prev {
    margin-left: -8px;
  }
  .slider-next {
    margin-left: 1.5rem;
  }

}

/* Swiper Animation */
.swiper-container {
  .swiper-slide {
    .down-up span {
      opacity: 0;
	  transform: translate3d(0,100%,0);
	  transition: all .4s ease-out;
    }
	
    .js-text-wave div span {
      transform: translate3d(0,100%,0);
    }
	
	.scaleCover {
      width: 100%;
	  height: 100%;
	  transform: scale(1.05);
	  transition: transform 1s cubic-bezier(.5,0,0,1);
	}
  }
  
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    .down-up span {
      opacity: 1;
      transform: translate3d(0,0%,0);
      transition-delay: .5s!important;
    }
	
    .js-text-wave div span {
      transform: translate3d(0,0,0);
    }

	.scaleCover {
	  transform: scale(1);
	}	
  }
  
  .swiper-slide-duplicate {
    .js-text-wave div span,
    .down-up span {
      transform: translate3d(0,100%,0);
    }
  }
}

.swiper-container-fade .swiper-slide {
  opacity: 1!important;
}
