@font-face {
  font-family: 'fox-icon';
  src:  url('./fox-icon.eot?bwfx0k');
  src:  url('./fox-icon.eot?bwfx0k#iefix') format('embedded-opentype'),
    url('./fox-icon.ttf?bwfx0k') format('truetype'),
    url('./fox-icon.woff?bwfx0k') format('woff'),
    url('./fox-icon.svg?bwfx0k#fox-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-fox-"], [class*=" icon-fox-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fox-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fox-bike:before {
  content: "\e900";
  color: #2c3f58;
}
.icon-fox-icon-wifi:before {
  content: "\e901";
  color: #2c3f58;
}
.icon-fox-laptop-computer:before {
  content: "\e902";
  color: #2c3f58;
}
.icon-fox-leaf:before {
  content: "\e903";
  color: #2c3f58;
}
.icon-fox-meeting-point:before {
  content: "\e904";
  color: #2c3f58;
}
.icon-fox-washing-machine:before {
  content: "\e905";
  color: #2c3f58;
}
