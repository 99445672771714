/* -----------------------------------------------------------------
    - Header
----------------------------------------------------------------- */

/* Logo */
.logo-link {
  display: block;
}
  
.logotype {
  width: 192px;
	
  @media only screen and (max-width: $medium) {
    width: 120px;
  }	

  @media only screen and (max-width: $small) {
    width: 104px;
  }
}

.header {
  .logo-link {
    .white {
      display: none;
    }
  }
  &.alternate{
    .logo-link {
      .black {
        display: none;
      }
      .white {
        display: block!important;
      }
    }
    .navbar__btn .nav-link{
      color:$black-color
    }

    .navbar__btn .dropdown-menu{
      border: 1px solid $black-color
    }
    
    .hamburger span{
      background-color: $black-color;
    }
  }
}

/* Navbar */  
.navbar {
  border-bottom: 1px solid rgba($gray-200-color,.2);
  padding: 1rem 3.5rem;
  
  @media only screen and (max-width: $large-xl) {
    padding: 1rem 2rem;
  }
  
  @media only screen and (max-width: $medium) {
    height: 5rem;
    padding: 0 1.5rem;
  }  
  
  @media only screen and (max-width: $small) {
    height: 3.15rem;
    padding: 0 15px;
  }

  &.beforeSticky{
    position: fixed;
    top: -100px;
    opacity: 0;
    transition: top 0.3s;
    z-index: 9999;

    &.sticky{
      background: #20394f;
      opacity: 1;
      top: 0;
      transition: top 0.3s;
    }
  }
  
  &__menu {
    line-height: 0;
  }
  
  &-overlay {
    position: absolute;
	top: 0;
	width: 100%;
	z-index: 3;
  }
  
  .nav {
    font-weight: $weight-600;

    .dropdown a {
      color: #fff;
      font-size: 15px;
      margin-right: 1.5rem;
      padding: 0;
      line-height: initial;

      .alternate &{ 
        color: $black-color;
      }
    }
    .dropdown-menu {
      min-width: 200px;
      a{
        padding: 5px 0;
        color: $black-color;
        line-height: initial;
      }
    }

    &.is-open{
      display: flex;
      transform: matrix(1, 0, 0, 1, 0, 0);
      right: 0;
    }

	  @media only screen and (max-width: $large) {
      // display: none;
      background: $white-color;
	    @include flex-direction(column);
      @include align-items(center);
      justify-content: center;
      font-weight: $weight-500;
      height: 100%;
	    @include position(fixed, $top: 0, $right: -100%);
      width: 100%;
      z-index: 9;	  
      transition: all .3s ease-out;;
	  }
	
    @media only screen and (max-width: $small) {

    }
	
	@media only screen and (min-width: 993px) {
	  display: flex!important;
	}
	
	&__item {
	  margin-right: 2.5rem;
	  position: relative;
	  
	  &:nth-last-child(2) {
	    margin-right: 0;
	  }
    
    &.hideDesktop{
      display: none;
      @media only screen and (max-width: $large) {
        display: block;
      }
    }
    
	  &:last-child {
	    display: none;
	    margin-right: 0;
		
		@media only screen and (max-width: $large) {
		  display: block;
		}
	  }
	  
	  @media only screen and (max-width: $large-xl) {
	    margin-right: 2rem;
	  }
	  
	  @media only screen and (max-width: $large) {
	    margin: 1rem 0;
	  }	  
	  
	  @media only screen and (max-width: $small) {
	    margin: .7rem 0;
	  }
	  
	  @media only screen and (min-width: $large) {
	    opacity: 1!important;
		transform: none!important;
	  }
	  
	  &.is-current .nav__link {
      color: $base-color;
      
	  }
	  
	  &.is-current::before {
	    content: '';
		background-color: $base-color;
		height: 1.5px;
		@include position(absolute, $left: 0, $bottom: -.6rem);
		width: 100%;
		
		@media only screen and (max-width: $large) {
		  display: none;
		}
	  }
	}
	
    &__link {
      color: $black-color;
	  display: block;
	  line-height: 1.1;
	  overflow: hidden;
	  position: relative;
	  white-space: nowrap;
	  text-decoration: none;
	  
	  @media only screen and (max-width: $large) {
	    font-size: 2rem;
	  }	  
	  
	  @media only screen and (max-width: $small) {
	    font-size: 1.4rem;
	  }
	  
	  &:hover span {
	    transform: translateY(-100%);
		
		@media only screen and (max-width: $large) {
		  color: $base-color;
		  transform: translateY(0);
		}
	  }
	  
	  span {
        display: inline-block;
        position: relative;
        transition: transform .2s ease-in-out;
		
		&::before {
          display: inline-block;
          width: 100%;
          height: 100%;
          content: attr(data-hover);
          position: absolute;
          left: 0;
          top: 100%;
          transform: translate3d(0,0,0);
        }
      }
	}
  }
  
  // button
  &__btn {
    @media only screen and (max-width: $large) {
	  display: none;
	}
  }
  
  // white nav
  &-white {
    border: 0;
	  padding: 1.5rem 3.5rem;
	
    @media only screen and (max-width: $large-xl) {
      padding: 1rem 2rem;
    }
  
    @media only screen and (max-width: $medium) {
      height: 5rem;
      padding: 0 1.5rem;
    }  
  
    @media only screen and (max-width: $small) {
      height: 3.15rem;
      padding: 0 15px;
    }	
	
	.nav__item.is-current::before {
	  background-color: $white-color;
  }
  
  .nav__item a.mobileLanguage.dropdown-item {
      display: inline-block!important;
      padding: 15px;
      width: auto;
  }
	
	.nav__item.is-current .nav__link {
	  color: $white-color;
	  
	  @media only screen and (max-width: $large) {
	    color: $black-color;
    }
  }
  
  .nav__link {
    color: $white-color;
    font-size: 15px;
	  
	  @media only screen and (max-width: $large) {
      color: $black-color;
      
	  }
    .alternate &{
      color: $black-color;

      .navbar__btn .nav-link{
        color:$black-color !important;
      }
    }
	}
    
	.hamburger span {
	  background-color: $white-color;
	}
  }
}

.is-sticky {
  box-shadow: 0px 24px 64px rgba($black-color,.06);
  border: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
  
  &.show {
    opacity: 1;
    transform: translateY(0)
  }

  &.hide {
    opacity:0;
    transform: translateY(-100%)
  }
}

/* Hamburger */
.hamburger {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  @include display(flex);
  @include flex-direction(column);
  justify-content: center;
  padding: 0;
  position: relative;
  height: 24px;
  width: 24px;
  z-index: 10;
  
  &:focus {
    outline: none;
  }
  
  @media only screen and (min-width: 993px) {
    display: none;
  }
  
  span {
	background-color: $black-color;
	height: 2px;
    width: 100%;
	position: absolute;
	top: 0;
    transition: transform .3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	
	&:nth-of-type(1) {
      top: 6px;
    }
	
    &:nth-of-type(2) {
      top: 16px;
    }
  }
  
  &.is-active span:nth-of-type(1) {
    transform: translateY(5px) rotate(-45deg);
  }

  &.is-active span:nth-of-type(2) {
    transform: translateY(-5px) rotate(45deg);
  }
}

.open-nav .hamburger span {
  background-color: $black-color;
}