/* -----------------------------------------------------------------
    - Gallery
----------------------------------------------------------------- */

.itemGallery {
  border-radius: 6px;
  flex: 0 0 calc(33.333333% - 30px);
  max-width: calc(33.333333% - 30px);
  margin: 0 15px 30px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  
  @media only screen and (max-width: $large-xl) {
    flex: 0 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
  
  @media only screen and (max-width: $medium) {
    flex: 0 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
  }  
  
  @media only screen and (max-width: $small) {
    margin: 0 15px 15px;
  }
  
  &::before {
    content: '';
    background: linear-gradient(180deg, rgba(42, 43, 48, 0) 48.92%, #2A2B30 81.35%);
    height: 100%;
    opacity: 0;
    pointer-events: none;
    @include position(absolute, $top: 0, $left: 0);
	transition: opacity .8s ease-in-out;
    width: 100%;
    z-index: 2;
  }
  
  &:hover {
    .itemGallery__details, .title {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
	
	.cover {
	  transform: scale(1.05);
    }
	
	&::before {
	  opacity: .5;
	}
  }
  
  .cover {
    display: block;
	transform: scale(1);
    transition: transform .8s cubic-bezier(0.2,1,0.8,1);
  }  

  &__link {
    display: block;
    position: relative;
    //width: 100%;
    text-decoration: none;
    z-index: 1;
  }
  
  &__details {
    padding: 0 1.5rem 1.2rem;
    position: absolute;
	bottom: 0;
	transform: translate3d(0, 100%, 0);
	transition: all .4s ease-in-out;
	z-index: 2;
	
	.title {  
      color: $white-color;
	  margin-bottom: 0;
	  opacity: 0;
	  transform: translate3d(0, 100%, 0);
	  transition: all .6s .1s ease-in-out;
	}
	
	.title--overhead {
	  font-size: .75rem;
	  margin-bottom: .3rem;
	}
  }
}

.touch {
  .itemGallery {
    .itemGallery__details, .title {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
    }
	
	&::before {
	  opacity: .5;
	}
  }
}