/* -----------------------------------------------------------------
    - Preloader
----------------------------------------------------------------- */

.preloader {
  @include display(flex);
  @include align-items(center);
  justify-content: center;
  background-color: $white-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  z-index: 1100;
  opacity: 1;

  &__wrap {
    width: 100%;
	max-width: 140px;
  }
  
  &__progress {
    background: rgba($gray-100-color,.8);
    height: 2px;
    position: relative;
	margin-top: 1.2rem;
	overflow: hidden;
	width: 100%;
	
	span {
	  background: rgba($base-color,1);
	  position: absolute;
	  top: 0;
	  left: -100%;
	  height: 100%;
	  width: 100%;
	  animation: line-anim 1.5s ease-in-out infinite;
	}
  }
}

@keyframes line-anim {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}