/* -----------------------------------------------------------------
    - Reservation
----------------------------------------------------------------- */

.reservation-page {
  @include display(flex);
  min-height: 100vh;
  height: 100%;
  
  &__wrap {
    max-width: 540px;
    margin: auto;
  }
  
  &__left {
    @include display(flex);
	@include flex-direction(column);
	@include align-items(center);
    background-color: $white-color;
	padding: 6vh 4rem;
    width: 42%;
	
	@media only screen and (max-width: $large-xxl) {
	  width: 48%;
	}	
	
	@media only screen and (max-width: $large-xl) {
	  width: 56%;
	}
	
	@media only screen and (max-width: $large) {
	  margin: 4vw;
	  width: 100%;
	}
	
	@media only screen and (max-width: $small) {
	  margin: 0;
      padding: 28px 15px 15px;
	}
  }
  
  &__right {
    background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex: 0 0 58%;
    width: 58%;
	
	@media only screen and (max-width: $large-xxl) {
	  flex: 0 0 52%;
	  width: 52%;
	}
	
	@media only screen and (max-width: $large-xl) {
	  flex: 0 0 44%;
	  width: 44%;
	}
	
	@media only screen and (max-width: $large) {
	  flex: auto;
	  position: fixed;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	  z-index: -1;
	}
	
	@media only screen and (max-width: $small) {
	  display: none;
	}
  }
}

/* Confirm */
.reservation-card-confirm {
  border: 1px solid rgba($gray-200-color,.2);
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  
  @media only screen and (max-width: $small) {
    padding: 1rem;
    margin-bottom: .5rem;
  }
  
  .line {
    background-color: $base-color;
    margin: 1rem 1.6rem;
    height: 1px;
	width: 8%;
	
	@media only screen and (max-width: $small) {
	  margin: .7rem 1rem;
	}
  }
}

.date-reserv {
  &__label {
    margin-bottom: .2rem;
  }
  
  &__date {
    font-size: 1.125rem;
    font-weight: $weight-600;
	
	@media only screen and (max-width: $small) {
	  font-size: .875rem;
	}
  }
}

.total-reserv {
  border-top: 1px solid rgba($gray-200-color,.2);
  font-size: 1.5rem;
  font-weight: $weight-600;
  line-height: 1.3;
  margin-top: 1.4rem;
  padding-top: 1.5rem;
  
  @media only screen and (max-width: $small) {
    font-size: 1.125rem;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}