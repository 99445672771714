/* -----------------------------------------------------------------
    - General Styles
----------------------------------------------------------------- */

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-x: hidden;
      overflow-x: hidden;
}
body, html {
  -webkit-text-size-adjust: 100%;

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #0c0c0c !important;
  }
}

// IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
  body, html {
    height: 100%;
  }
}

body {
  background-color: $white-color;
  color: $black-color;
  font-family: $font-body;
  font-size: 16.5px;
  font-weight: $weight-400;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
  
  line-height: 1.75;
  min-height: 100vh;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;  
  
  @media only screen and (max-width: $small) {
    font-size: .9375rem;
  }
}

html.is-scroll-disabled body {
  height: 100vh;
  overflow: hidden;
  
  @media only screen and (min-width: $large) {
    overflow: auto!important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.is-scroll-disabled {
    overflow: hidden;
  }
}

.no-scroll {
  overflow: hidden;
  -ms-overflow-style: none;
}

.no-scroll-ios {
  position: fixed;
  width: 100%;
}

