/* Buttons */
.btn {
  background-color: $base-color;
  border: 0;
  border-radius: 5rem;
  box-shadow: 0px 16px 48px rgba($base-color,.16);
  color: $white-color;
  cursor: pointer;
  font-weight: $weight-600;
  display: inline-flex;
  @include align-items(center);
  @include flex-wrap(nowrap);
  justify-content: center;
  line-height: 0;
  height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  z-index: 1;
  transition: box-shadow .4s ease-in-out;

  &::before {
    content: '';
    background: rgba($white-color,.1);
	display: block;
    width: 100%;
    height: 100%;
	@include position(absolute, $top: 0, $left: 0);
    opacity: 1;
    transform: translateX(-100%) skewX(-15deg);
	transition: transform .4s ease-in-out;
  }
  
  &:hover {
    color: $white-color;
    box-shadow: 0px 0px 0px rgba($base-color,.16);
	
	&::before {
      transform: translateX(100%) skewX(-15deg);  
      opacity: 1;
    }
  }
  
  &:focus {
    color: $white-color;
    box-shadow: none;
  }
  
  @media only screen and (max-width: $small) {
    font-size: .9375rem;
    height: 2.75rem;
	padding: 0 1rem;
  }
  
  &.disabled {
    opacity: 1;
  }
  
  .btn-icon-left,
  .btn-icon-right {
    font-size: 1.1rem;
  }
  
  .btn-icon-left {
	padding-right: .45rem;
  }
  
  .btn-icon-right {
	padding-left: .45rem;
  }  
  
  // Button circle
  &__circle {
    padding: 0;
    height: 3rem;
	width: 3rem;
  }
  
  // Button large
  &__large {
    height: 3.8rem;
	padding: 0 2.5rem;
	
	@media only screen and (max-width: $large) {
	  height: 3.6rem;
	}	
	
	@media only screen and (max-width: $medium) {
	  height: 3.4rem;
	}
	
	@media only screen and (max-width: $small) {
	  height: 2.75rem;
	  padding: 0 1.5rem;
	}
	
	.btn-icon-left,
    .btn-icon-right {
      font-size: 1.3rem;
	}
  
    .btn-icon-left {
	  padding-right: .6rem;
    }
  
    .btn-icon-right {
	  padding-left: .6rem;
    }
  }  
  
  // Button medium
  &__medium {
    height: 3rem;
	padding: 0 2rem;
	
	@media only screen and (max-width: $small) {
	  height: 2.75rem;
	}
  }
  
  // Button small
  &__small {
    font-size: .875rem;
	font-weight: $weight-500;
    height: 2.5rem;
	padding: 0 1rem;
	
	@media only screen and (max-width: $small) {
	  height: 2.375rem;
	}
  }
  
  // Button second
  &__second {
    background-color: transparent;
	border: 1px solid rgba($gray-200-color,.2);
	box-shadow: none;
	color: $gray-300-color;
	
	&::before {
	  display: none;
	}
	
	&:hover {
	  border: 1px solid rgba($gray-200-color,.6);
	  color: $gray-300-color;
	}
	
	&:focus {
	  color: $gray-300-color;
	}
  }
  
  // Button white
  &--white {
    background-color: $white-color;
	box-shadow: none;
	color: $base-color;
	
	&::before {
      background: rgba($base-color,.1);
	}
	
	&:hover {
	  color: $base-color;
	}
  }
}

/* Button play */
.btn-play {
  $size: 4rem;
  background-color: $white-color;
  background-image: url(../../icons/icon-play.svg);
  background-size: 22%;
  background-position: center;
  border-radius: 50%;
  box-shadow: none;
  display: block;
  height: $size;
  position: relative;
  transition: all .5s ease-in-out; 
  width: $size;
  
  &::before {
    content: '';
	border: 1px solid rgba($white-color,.1);
	border-radius: 50%;
	height: 148%;
	margin: auto;
	@include position(absolute, $top: 0, $left: -50%, $bottom: 0, $right: -50%);
	width: 148%;
  }
  
  &:hover,
  .header__btn-play:hover & {
    box-shadow: 0 0 0 .5rem $white-color;
  }
  
  @media only screen and (max-width: $small) {
    height: calc(#{$size} / 1.4);
	width: calc(#{$size} / 1.4);
  }
  
  &__popup {
    &::after {
	  content: '';
	  background-color: $white-color;
	  //box-shadow: 0 0 0 0 $white-color;
	  border-radius: 50%;
	  height: 2rem;
	  margin: auto;
	  @include position(absolute, $top: 0, $left: 0, $bottom: 0, $right: 0);
	  width: 2rem;
	  //transform: scale(1);
	  //transition: transform .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	  z-index: -1;
	}
	
	&.is-active {
	  //box-shadow: 0 0 0 100vw $white-color;
	  transform: scale(60);
	  
	  &::after {
	    z-index: 1;
	  }
	}
  }
}

/* Button booking */
.btn-booking {
  background-color: $base-color;
  border: 0;
  box-shadow: 0px 16px 48px rgba($base-color,.16);
  color: $white-color;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: $weight-600;
  @include display(flex);
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(flex-start);
  justify-content: center;
  flex: 0 0 auto;
  height: 100%;
  line-height: inherit;
  letter-spacing: .1rem;
  white-space: nowrap;
  overflow: hidden;
  padding: 1rem 2.6rem .8rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  
  &:focus {
	outline: none;
  }
  
  @media only screen and (max-width: $large) {
    border-radius: 5rem;
	font-size: 1rem;
	@include align-items(center);
	height: 3rem;
	padding: 0 2rem;
  }
  
  @media only screen and (max-width: $small) {
    font-size: .9375rem;
    height: 2.75rem;
  }
  
  // IE
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	@media only screen and (max-width: $large) {
	  width: 100%;
	}
  }  
  
  span {
    font-size: .75rem;
	font-weight: $weight-400;
	margin-bottom: .3rem;
	position: relative;
	
	&::before {
	  content: '';
	  background-color: $white-color;
	  height: 1px;
	  margin-top: auto;
	  margin-bottom: auto;
	  @include position(absolute, $top: 0, $right: -30px, $bottom: 0);
	  width: 20px;
	}
	
	@media only screen and (max-width: $large) {
	  display: none;
	}
  }
}

/* Button link */
.btn-link {
  display: inline-flex;
  @include align-items(center);
  color: $base-color;
  font-size: 1.125rem;
  padding-right: 4.5rem;
  position: relative;
  transition: all .3s ease-in-out;
  
  &::before {
    content: '';
	background-color: $base-color;
	height: 1px;
	margin: auto 0;
	@include position(absolute, $top: 0, $bottom: 0, $right: 0);
	width: 3rem;
	transition: all .3s ease-in-out;
  }
  
  &:hover {
    color: $base-color;
	text-decoration: none;
	padding-right: 3.5rem;
	
	&::before {
	  width: 2rem;
	}
  }
  
  &:focus {
    color: $base-color;
    text-decoration: none;
  }
  
  @media only screen and (max-width: $small) {
    font-size: .875rem;
	padding-right: 4rem;
  }
}

/* Button floating */
.btn-floating {
  $size: 4rem;
  @include display(flex);
  @include align-items(center);
  justify-content: center;
  background-color: $white-color;
  box-shadow: 0px 64px 64px rgba($black-color, 0.05), 
              0px 0px 32px rgba($black-color, 0.05), 
			  0px 16px 16px rgba($black-color, 0.03), 
			  0px 8px 8px rgba($black-color, 0.03), 
			  0px 4px 4px rgba($black-color, 0.03), 
			  0px 2px 2px rgba($black-color, 0.04);
  border-radius: 50%;
  font-size: 1.6rem;
  color: $base-color;
  cursor: pointer;
  height: $size;
  width: $size;
  @include position(fixed, $right: 3.5rem, $bottom: 3.5rem);
  transition: all .3s ease-in-out;
  z-index: 1;
  
  &:hover {
    color: $white-color;
    box-shadow: 0px 64px 64px rgba($black-color, 0.05), 
              0px 0px 32px rgba($black-color, 0.05), 
			  0px 16px 16px rgba($black-color, 0.03), 
			  0px 8px 8px rgba($black-color, 0.03), 
			  0px 4px 4px rgba($black-color, 0.03), 
			  0px 2px 2px rgba($black-color, 0.04),
			  inset 0 0 0 60px $base-color;
  
  }
  
  @media only screen and (max-width: $large-xl) {
    right: 2.5rem;
  }
  
  @media only screen and (max-width: $medium) {
    height: calc(#{$size} / 1.14);
    width: calc(#{$size} / 1.14);
    right: 2rem;
	bottom: 8.5rem;
  } 
  
  @media only screen and (max-width: $small) {
    display: none!important;
  }
  
  i {
    @include display(flex);
    @include align-items(center);
    justify-content: center;
	height: 100%;
  }
  
  &.is-bottom {
    bottom: 8rem;
  }
}