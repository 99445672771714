/* -----------------------------------------------------------------
    - 404
----------------------------------------------------------------- */ 

.mask-object {
  //filter: drop-shadow(0 20px 30px rgba($black-color,.1));
  margin-bottom: 2rem;
  width: 30vw;
  transition: all .8s cubic-bezier(0.2,1,0.8,1);
  
  @media only screen and (max-width: $large) {
    width: 50vw;
  }
  
  @media only screen and (max-width: $small) {
    margin-bottom: 1rem;
    width: 60vw;
  }  
}

.bg-mask {
  @include position(absolute, $top: 0, $left: 0, $bottom: 0, $right: 0);
  height: 100%;
  width: 100%;
  z-index: -2;
  
  &__image {
    display: block;
    @include position(fixed, $top: 0, $left: 0);
	height: 100%;
	object-fit: cover;
    width: 100%;
    z-index: -1;
  }
}