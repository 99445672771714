/* -----------------------------------------------------------------
    - Item Room
----------------------------------------------------------------- */

.itemRoom {
  background-color: $white-color;
  color: $white-color;
  margin-bottom: 1.875rem;
  //height: 370px;
  //overflow: hidden;
  cursor: pointer;
  position: relative;
  
  @media only screen and (max-width: $small) {
    margin-bottom: 15px;
  }
  
  &__img-wrap {
    border-radius: 6px;
    height: 270px;
	margin-bottom: 0;
	overflow: hidden;
	position: relative;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	
    &::before {
      content: '';
	  background: linear-gradient(180deg, rgba(42, 43, 48, 0) 48.92%, #2A2B30 81.35%);
	  height: 100%;
	  opacity: .5;
	  pointer-events: none;
	  @include position(absolute, $top: 0, $left: 0);
	  width: 100%;
	  z-index: 2;
    }
	
    @media only screen and (max-width: $large-xxl) {
      height: 350px;
    }
  
    @media only screen and (max-width: $large-xl) {
      height: 290px;
    }
  
    @media only screen and (max-width: $large) {
      height: 330px;
    }
  
    @media only screen and (max-width: $medium) {
      height: 250px;
    }
    @media only screen and (max-width: $small) {
      height: 170px; 
    }  
  }
  
  &__portrait .itemRoom__img-wrap {
    height: 520px;
	
	@media only screen and (max-width: $small) {
	  height: 360px;
	}
  }  
  
  &.swiper-slide {
    margin-bottom: 0;
  }
  
  .cover {
	transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.2,1,0.8,1);
	z-index: -10;
  }  
  
  &:hover .cover {
	transform: scale(1.05);
  }  
  
  &__link {
    height: 100%;
	@include position(absolute, $top: 0, $left: 0);
	width: 100%;
	z-index: 1;
  }
  
  &__details {
    padding: 1.5rem;
	@include position(absolute, $left: 0, $bottom: 0);
	z-index: 3;
	
	@media only screen and (max-width: $small) {
	  padding: 1rem;
	}
  }
  
  .title {
    margin-bottom: .9rem;
	
	@media only screen and (max-width: $large-xl) {
      font-size: 1.3rem;
    }
	
	@media only screen and (max-width: $small) {
      font-size: 1.15rem;
	  margin-bottom: .7rem;
    } 	
  }
  
  .badge {
    margin: 1.5rem 1.5rem 0 0;
	@include position(absolute, $top: 0, $right: 0);
	z-index: 3;
	
	@media only screen and (max-width: $small) {
	  margin: 1rem 1rem 0 0;
	}
  }
  
  &__price {
    font-size: 1.5rem;
	font-weight: $weight-600;
	line-height: 1;
	
	span {
	  font-size: 1rem;
	  font-weight: $weight-400;
	  opacity: .8;
	  // margin-left: .5rem;
	}
	
	@media only screen and (max-width: $large-xl) {
	  font-size: 1.3rem;
	  
	  span {
	    font-size: .875rem;
	  }
	}
  }
  
  // Main
  &__main {
	margin-bottom: 5rem;
	
	@media only screen and (max-width: $large-xl) {
	  margin-bottom: 3rem;
	}
    
	@media only screen and (max-width: $small) {
	  margin-bottom: 1.5rem;
	}
	
	.itemRoom__img-wrap {
	  height: 640px;
	  
	  @media only screen and (max-width: $large-xl) {
	    height: 540px;
	  }
	  
	  @media only screen and (max-width: $large) {
	    height: 440px;
	  }
	}
	
	.itemRoom__details {
	  background-color: $white-color;
	  border-radius: 0 6px 0 0;
	  color: $black-color;
	  padding-right: 2.5rem;
	  
	  @media only screen and (max-width: $small) {
	    padding-right: 1rem;
	  }
	}
	
	.itemRoom__price {
	  font-size: 2rem;
	  
	  span {
	    font-size: 1.125rem;
	  }
	  
	  @media only screen and (max-width: $small) {
	    font-size: 1.5rem;
		
		span {
	      font-size: .875rem;
	    }
	  }
	}
  }
  
  // Extended
  &__extended .itemRoom__img-wrap {
    border-radius: 6px 6px 0 0;
  }
  
  &__details-extended {
    box-shadow: 0px 24px 60px rgba($black-color,.0);
	border-radius: 0 0 6px 6px;
    color: $gray-300-color;
	font-size: .9375rem;
	line-height: 1;
	padding: 1.5rem;
	transition: box-shadow .3s ease-in-out;
	
	@media only screen and (max-width: $small) {
	  font-size: .875rem;
	  box-shadow: none;
	  padding: 1.2rem 0 1rem;
	}
	
	.item-extended {
	  display: inline-flex;
      align-items: flex-end;
	  
	  &:first-child {
	    margin-bottom: 1.2rem;
	    width: 100%;
	  }
	  
	  @media only screen and (max-width: $small) {
	    align-items: center;
		
		&:first-child {
	      margin-bottom: 1rem;
		}
	  }
	}
	
	[class^="icon-"], [class*=" icon-"] {
	  font-size:1.125rem;
	  margin-right: .6rem;
	}
  }
  
  &:hover .itemRoom__details-extended {
    box-shadow: 0px 24px 60px rgba($black-color,.07);
  }
  
  &__extended.swiper-slide .itemRoom__details-extended {
    @media only screen and (max-width: $small) {
	  padding: 1.2rem 1rem 1rem;
	}
  }
}

/* -----------------------------------------------------------------
    - Room details
----------------------------------------------------------------- */

.slider-room {
  position: relative;
  height: 480px;
  margin-bottom: 3.5rem;
  width: 100%;
  
  @media only screen and (max-width: $large-xl) {
    height: 540px;
  }
  
  @media only screen and (max-width: $large) {
    height: 480px;
  }  
  
  @media only screen and (max-width: $medium) {
    margin-top: 2rem!important;
    height: 420px;
  }
  
  @media only screen and (max-width: $small) {
    margin-top: 2rem!important;
	margin-bottom: 2.5rem;
    height: 230px;
  }    
  
  .swiper-slide {
    height: 100%;
	overflow: hidden;
	position: relative;
  }
}

/* Sidebar booking */
.sidebar-booking {
  margin-top: -8rem;
  position: relative;
  z-index: 1;
  transition: all .3s cubic-bezier(0.2,1,0.8,1);
  
  @media only screen and (max-width: $large-xl) {
    margin-top: 3rem;
  }
  
  @media only screen and (max-width: $large) {
    margin-top: 0;
  }
  
  @media only screen and (max-width: $small) {
    margin-bottom: .6rem;
  }  
  
  &.is_stuck {
    margin-top: 7rem;
	
	.sidebar-booking__priceWrap {
	  border-radius: 6px 6px 0 0;
	}
  }
  
  &__priceWrap {
    background-color: $black-color;
	color: $white-color;
    @include display(flex);
    @include flex-direction(column);
    justify-content: center;
    height: 8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
	
	@media only screen and (max-width: $large-xl) {
	  background-color: $white-color;
	  border: 1px solid rgba($gray-200-color,.2);
	  border-radius: 6px 6px 0 0;
	  color: $black-color;
	  height: 7rem;
	}
	
	@media only screen and (max-width: $small) {
	  height: auto;
	  padding: 8px 15px;
	}
  }  
  
  .priceWrap-title {
    font-size: .75rem;
	letter-spacing: .1rem;
	padding-top: .7rem;
    text-transform: uppercase;
	
	@media only screen and (max-width: $small) {
	  padding-top: .6rem;
	}
  }
  
  .priceWrap-price {
	font-size: 2rem;
	font-weight: $weight-600;
	
    span {
	  font-size: 1.125rem;
	  font-weight: $weight-400;
	}
	  
	@media only screen and (max-width: $small) {
	  font-size: 1.5rem;
		
	  span {
	    font-size: .875rem;
	  }
	}
  }  
  
  &__wrap {
    background-color: $white-color;
    border: 1px solid rgba($gray-200-color,.2);
    border-top: 0;
    padding: 1.5rem 1.5rem 1.1rem;
    border-radius: 0 0 6px 6px;
    
	@media only screen and (max-width: $small) {
	  padding: 15px;
	}
	
    .row {
      margin-left: -12px;
	  margin-right: -12px;
    }
  
    .col-12 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  
  &__note {
    display: block;
    font-size: .875rem;
    padding-top: 1.1rem;
    text-align: center;
	width: 100%;
	
	@media only screen and (max-width: $small) {
	  font-size: .75rem;
      padding-top: 1rem;
	}
  }  
}

/* Map */
.map-bottom {
  background-color: $gray-100-color;
  height: 300px;
  margin-top: 4rem;
  width: 100%;
  overflow: hidden;
  
  @media only screen and (max-width: $large) {
    margin-top: 3rem;
  }
  
  @media only screen and (max-width: $small) {
    border-radius: 6px;
	height: 300px;
    margin: 15px;
	width: calc(100% - 30px);
	-webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  .mapboxgl-canvas {
    width: 100% !important;
  }
}

.map-sidebar {
  background-color: $gray-100-color;
  border-radius: 6px;
  height: 480px;
  margin-top: 2rem;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  
  @media only screen and (max-width: $large-xl) {
    height: 360px;
  }
  
  @media only screen and (max-width: $small) {
    height: 260px;
    margin-top: 15px;
	margin-bottom: .5rem;
  }
}

.react-datepicker-popper {
    z-index: 10;
}