.navbar__btn {
    // display: flex;
    font-family: "Roboto", sans-serif;
    font-weight: 600;

    @media only screen and (min-width: 992px) {
        display: flex;
    }
    
    .nav-link{
        color: #fff;
    }

    .dropdown-menu {
        min-width: auto;
        padding: 10px;

        .dropdown-item{
            padding: 0 10px;
        }
    }
}