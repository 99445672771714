main > .container {
  /* padding: 60px 15px 0; */
}

.loader {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
