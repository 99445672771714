/* -----------------------------------------------------------------
    - Other
----------------------------------------------------------------- */

/* Badge */
.badge {
  border: 1px solid rgba($white-color,.2);
  border-radius: 2rem;
  font-size: .875rem;
  font-weight: $weight-600;
  padding: .55rem 1.125rem;
  
  @media only screen and (max-width: $small) {
    font-size: .75rem;
    font-weight: $weight-500;
    padding: .4rem .8rem;
  }
}

.back-decor {
  color: rgba($gray-100-color,.6);
  font-size: 12vw;
  font-weight: $weight-600;
  letter-spacing: -.05rem;
  position: absolute;
}

/* Scroll to */
.scroll-to {
  color: $white-color !important;
  font-family: $font-body;
  font-size: .75rem;
  padding-bottom: 50px;
  position: absolute;
  bottom: 8rem;
  right: 3.5rem;
  writing-mode: vertical-rl;
  z-index: 2;
    
  @media only screen and (max-width: $large-xl) {
    right: 2rem;
  }
  
  @media only screen and (max-width: $large) {
    bottom: 13.5rem;
  }
  
  @media only screen and (max-width: $medium) {
    bottom: 2rem;
  }
  
  @media only screen and (max-width: $small) {
    padding-bottom: 40px;
    right: 15px;
	bottom: 40px;
  }
  
  // IE
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
    writing-mode: bt-rl;
  }
  
  &__line {
    display: block;
	height: 30px;
	margin: auto;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	width: 1px;
	
    &::before {
      content: '';
	  background-color: $white-color;
	  height: 100%;
	  position: absolute;
	  bottom: -100%;
	  width: 100%;
	  animation: scroll-to 1s ease-in-out infinite;
    }
	
	@media only screen and (max-width: $small) {
	  height: 20px;
	}
  }
}

@keyframes scroll-to {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 100%;
  } 
}


/* Split text (line) */
.js-lines {
  // overflow: hidden;
  
  div {
    overflow: hidden;
  }
  
  .tl-line {
    padding-bottom: .2rem;
  }
}

/* Text wave */
.js-text-wave {
  opacity: 0;
  
  &.complete {
    opacity: 1;
  }
  
  div {
    display: inline-block;
    overflow: hidden;
  }
  
  div span {
    display: inline-block;
    transform: translate3d(0,100%,0);
    transition: transform 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  }
  
  &.complete div span {
    transform: translate3d(0,0,0);
  }
}

/* DownUp */
.down-up {
  display: block;
  margin-top: -5px;
  overflow: hidden;
  position: relative;
  span {
    display: block;
	transform: translate3d(0,100%,0);
	transition: opacity, transform 0.75s cubic-bezier(0.8, 0, 0.2, 1);
  }
  &.animated span {
    opacity: 1;
	transform: translate3d(0px, 0%, 0px);
  }
}

/* Jarallax */
.js-parallax {
  position: relative;
  z-index: 0;
}

.js-parallax-img {
  position: relative;
  z-index: 0;
  
  .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

/* Slash */
.slash {
  position: relative;
  
  &::before {
    content: '';
    background-color: rgba($gray-200-color, .3);
    height: 50px;
    margin: auto;
	@include position(absolute, $top: 0, $bottom: 0);
	width: 1px;
	transform: rotate(30deg);
    transform-origin: center;
    z-index: 1;
  }
}

/* Progress */
.progressBar {
  margin-bottom: 1.5rem;
  
  @media only screen and (max-width: $small) {
    margin-bottom: 1rem;
  } 
  
  &__wrap {
    background-color: $gray-100-color;
    border-radius: 2px;
    height: 4px;
	overflow: hidden;
  }
  
  &__current {
    background-color: $base-color;
	border-radius: 2px;
	height: 100%;
	transition: width .75s cubic-bezier(0.8, 0, 0.2, 1);
	width: 0%;
  }
  
  &__label {
    @include display(flex);
	justify-content: space-between;
    font-size: 1rem;
	padding-bottom: .5rem;
	
	@media only screen and (max-width: $small) {
	  font-size: .875rem;
      padding-bottom: .3rem;
	}
	
	span {
	  font-weight: $weight-600;
	}
  }
}

/* Pagination */
.page-link {
  $size: 2.5rem;
  @include display(flex);
  @include align-items(center);
  justify-content: center;
  color: $gray-300-color;
  background-color: transparent;
  border-radius: 50%!important;
  border: 0;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
  margin: 0 .3rem;
  height: $size;
  width: $size;
	
  &:hover {
    background-color: inherit;
	color: $black-color;
  }
	
  &:focus {
    box-shadow: none;
    outline: none;
  }	
  
  @media only screen and (max-width: $small) {
    $size: 1.5rem;
	font-size: .875rem;
	margin: 0 .2rem;
    height: $size;
    width: $size;
  }
  
  &.current {
    color: $base-color;
  }
  
  &__control {
	border: 1px solid rgba($gray-300-color,.2);
	color: rgba($gray-300-color,.5);
	margin: 0;
	
	&:hover {
	  color: $black-color;
      border: 1px solid rgba($gray-300-color,0);
	  border-radius: 30px;
	  box-shadow: 0px 0px 64px rgba($black-color, .05), 
	              0px 0px 32px rgba($black-color, .05), 
				  0px 16px 16px rgba($black-color, .03), 
				  0px 8px 8px rgba($black-color, .03), 
				  0px 4px 4px rgba($black-color, .03), 
				  0px 2px 2px rgba($black-color, .04);
    }
  }
}

// Scroll Indicator
.scroll-line{
  background: $base-color;
  height: 3px;
  @include position(fixed, $top: 0);
  width: 0;
  z-index: 1;
}

/* Mapbox */
#marker {
  background-image: url('/images/ui/icon-location.svg');
  background-size: cover;
  width: 64px;
  height: 64px;
  cursor: pointer;
  
  @media only screen and (max-width: $small) {
    width: 40px;
   height: 40px;
  }
}
