.background-1,.background-2,.background-3,.background-4,.background-5 {
    position: relative;
    &:after {
        content: "";
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
    }
}
.background-1 {
    &:after {
        background: rgba(49, 80, 111, .5);
    }
}
.background-2 {
    &:after {
        background: rgba(207, 171, 48, .5);
    }
}
.background-3 {
    &:after {
        background: rgba(1, 128, 39, 0.25);
    }
}
.background-4 {
    &:after {
        background: rgba(0, 72, 128, .5);
    }
}
.background-5 {
    &:after {
        background: rgba(128, 0, 1, .5);
    }
}

.background-5 {
    &:before {
        content: "Coming soon";
        display: block;
        font-family: "Playfair Display", serif;
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        color: #ffffff;
        font-weight: bold;
        transform: translate(-50%,-50%);
        font-size: 24px;
    }
}