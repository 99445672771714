// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i&display=swap');

// Setings
$font-body: 'Roboto', sans-serif;
$font-title: 'Playfair Display', serif;

// Weight
$weight-400: 400; //regular
$weight-500: 500; //medium
$weight-600: 600; //semi bold
$weight-700: 700; //bold
