// Dropdown
.dropdown-menu {
  background-color: $white-color;
  box-shadow: 0px 48px 64px rgba($black-color,.1);
  border-radius: 6px;
  border: 0;
  min-width: 20rem;
  padding: 1.5rem;
  
  @media only screen and (max-width: $small) {
    padding: 1rem;
	min-width: 100%;
  }
  
  .col__left {
    padding-right: 12px;
	
    @media only screen and (max-width: $small) {
      padding-right: 7.5px;
	}
  }

  .col__right {
    padding-left: 12px;
	
    @media only screen and (max-width: $small) {
      padding-left: 7.5px;
	}
  }  

  .row-footer {
    margin-top: 1.2rem;
	
	@media only screen and (max-width: $small) {
	  margin-top: .5rem;
	}
  }
  
  .form-group {
    margin-right: 0!important;
	
	@media only screen and (max-width: $small) {
	  margin-bottom: .6rem!important;
	}
  }
}