@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap');

.card-wrapper {
  direction: ltr;
  text-align: left;
}
.jp-card.jp-card-safari.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-safari.jp-card-identified .jp-card-back:before {
  /* background-image: linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); */
  background-image: url(/images/ui/card-shine.svg);
  background-size: cover;
}
.jp-card.jp-card-ie-10.jp-card-flipped,
.jp-card.jp-card-ie-11.jp-card-flipped {
  transform: 0deg;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-front,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-front {
  transform: rotateY(0deg);
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back {
  transform: rotateY(0deg);
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back:after,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back:after {
  right: 8%;
  left: auto;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-cvc,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-cvc {
  transform: rotateY(180deg);
  right: auto;
  left: 8%;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny {
  left: 84%;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny:after,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny:after {
  left: -480%;
  transform: rotateY(180deg);
}
.jp-card.jp-card-ie-10.jp-card-amex .jp-card-back,
.jp-card.jp-card-ie-11.jp-card-amex .jp-card-back {
  display: none
}
.jp-card-logo {
  height: 36px;
  width: 60px;
  /* font-style: italic */
}
.jp-card-logo,
.jp-card-logo:before,
.jp-card-logo:after {
  box-sizing: border-box
}

.jp-card-logo.jp-card-amex {
  background-image: url(/images/ui/american-express.svg);
  height: 35px;
  width: 35px;	
}
.jp-card.jp-card-amex.jp-card-flipped {
  transform: none
}
.jp-card.jp-card-amex.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-amex.jp-card-identified .jp-card-back:before {
  background-color: #108168
}
.jp-card.jp-card-amex.jp-card-identified .jp-card-front .jp-card-logo.jp-card-amex {
  opacity: 1
}
.jp-card.jp-card-amex.jp-card-identified .jp-card-front .jp-card-cvc {
  visibility: visible
}
.jp-card.jp-card-amex.jp-card-identified .jp-card-front:after {
  opacity: 1
}
.jp-card-logo.jp-card-discover {
  background-image: url(/images/ui/discover.svg);
  height: 21px;
  width: 128px;
}
.jp-card .jp-card-front .jp-card-logo.jp-card-discover {
  right: 10%;
  top: 15%
}
.jp-card.jp-card-discover.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-discover.jp-card-identified .jp-card-back:before {
  background-color: #86B8CF
}
.jp-card.jp-card-discover.jp-card-identified .jp-card-logo.jp-card-discover {
  opacity: 1
}
.jp-card.jp-card-discover.jp-card-identified .jp-card-front:after {
  transition: 400ms;
  content: " ";
  display: block;
  background-color: #f60;
  background-image: -webkit-linear-gradient(#f60, #ffa366, #f60);
  background-image: linear-gradient(#f60, #ffa366, #f60);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  left: 100%;
  top: 12%;
  margin-left: -17.5px;
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.5)
}
@media only screen and (max-width: 580px) {
  .jp-card.jp-card-discover.jp-card-identified .jp-card-front:after {
    height: 29px;
	margin-left: -14.5px;
    width: 29px;
  }  
}
.jp-card-logo.jp-card-visa {
  background-image: url(/images/ui/visa.svg);
  height: 24px;
  width: 72px;
}
.jp-card.jp-card-visa.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-visa.jp-card-identified .jp-card-back:before {
  background-color: #191278
}
.jp-card.jp-card-visa.jp-card-identified .jp-card-logo.jp-card-visa {
  opacity: 1;
  box-shadow: none
}
.jp-card-logo.jp-card-visaelectron {
  background-image: url(/images/ui/visa-electron.svg);
  height: 33px;
  width: 74px;
}
.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-back:before {
  background-color: #191278
}
.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-logo.jp-card-visaelectron {
  opacity: 1
}
.jp-card-logo.jp-card-mastercard {
  background-image: url(/images/ui/mastercard.svg);
  height: 35px;
  width: 45px;
}
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-front .jp-card-logo.jp-card-mastercard,
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-back .jp-card-logo.jp-card-mastercard {
  box-shadow: none
}
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-back:before {
  background-color: #0055C7; /*0061A8*/
}
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-logo.jp-card-mastercard {
  opacity: 1
}
.jp-card-logo.jp-card-maestro {
  background-image: url(/images/ui/maestro.svg);
  height: 35px;
  width: 45px;
}
.jp-card.jp-card-maestro.jp-card-identified .jp-card-front .jp-card-logo.jp-card-maestro,
.jp-card.jp-card-maestro.jp-card-identified .jp-card-back .jp-card-logo.jp-card-maestro {
  box-shadow: none
}
.jp-card.jp-card-maestro.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-maestro.jp-card-identified .jp-card-back:before {
  background-color: #52325d;
}
.jp-card.jp-card-maestro.jp-card-identified .jp-card-logo.jp-card-maestro {
  opacity: 1
}
.jp-card-logo.jp-card-dankort {
  background-image: url(/images/ui/dankort.svg);
  height: 35px;
  width: 59px;	
}

.jp-card.jp-card-dankort.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-dankort.jp-card-identified .jp-card-back:before {
  background-color: #E31F28; /*0055C7*/
}
.jp-card.jp-card-dankort.jp-card-identified .jp-card-logo.jp-card-dankort {
  opacity: 1
}
.jp-card-logo.jp-card-elo {
  background-image: url(/images/ui/elo.svg);
  height: 31px;
  width: 83px;	
}
.jp-card.jp-card-elo.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-elo.jp-card-identified .jp-card-back:before {
  background-color: #004d73;
}
.jp-card.jp-card-elo.jp-card-identified .jp-card-logo.jp-card-elo {
  opacity: 1
}
.jp-card-logo.jp-card-jcb {
  background-image: url(/images/ui/jcb.svg);
  height: 35px;
  width: 46px;	
}
.jp-card.jp-card-jcb.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-jcb.jp-card-identified .jp-card-back:before {
  background-color: #279b37
}
.jp-card.jp-card-jcb.jp-card-identified .jp-card-logo.jp-card-jcb {
  opacity: 1;
  box-shadow: none
}
.jp-card-logo.jp-card-dinersclub {
  background-image: url(/images/ui/DinersClub.svg);
  height: 29px;
  width: 115px;
  top: 13%;
}
.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-back:before {
  background-color: #6a737b; 
}
.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-logo.jp-card-dinersclub {
    opacity: 1
}
.jp-card-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  width: 350px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  z-index: 1;
  position: relative;
  transform: scale(.8)!important;
}
@media only screen and (max-width: 580px) {
  .jp-card-container {
	height: 170px;
	margin-bottom: 1.4rem;
    width: 290px;
    transform: scale(1)!important;
  }
}
.jp-card {
  font-family: 'Roboto Mono', monospace;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 400ms linear
}
@media only screen and (max-width: 580px) {
  .jp-card {
	min-width: 290px;
  }
}
.jp-card>*,
.jp-card>*:before,
.jp-card>*:after {
  box-sizing: border-box;
  font-family: inherit
}
.jp-card.jp-card-flipped {
  transform: rotateY(180deg)
}
.jp-card .jp-card-front,
.jp-card .jp-card-back {
  /* background-image: linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); */
  background-image: url(/images/ui/card-shine.svg);
  background-size: cover;
  background-color: #C6CAD3;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 400ms linear;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  
}
.jp-card .jp-card-front:before,
.jp-card .jp-card-back:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 10px;
  transition: all 400ms ease
}
.jp-card .jp-card-front:after,
.jp-card .jp-card-back:after {
  content: " ";
  display: block
}
.jp-card .jp-card-back-text {
  background-image: url(/images/ui/back-text-card.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 36px;
  margin-left: 8%;
  margin-right: 8%;
  width: calc(100% - 16%);
  position: absolute;
  bottom: 25px;
}	
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-back-text {
    bottom: 16px;
  }	  
}
.jp-card .jp-card-front .jp-card-display,
.jp-card .jp-card-back .jp-card-display {
  color: #fff;
  font-weight: normal;
  opacity: 0.5;
  transition: opacity 400ms linear
}
.jp-card .jp-card-front .jp-card-display.jp-card-focused,
.jp-card .jp-card-back .jp-card-display.jp-card-focused {
  opacity: 1;
  /* font-weight: 700; */
}
.jp-card .jp-card-front .jp-card-cvc,
.jp-card .jp-card-back .jp-card-cvc {
  font-size: 16px
}
.jp-card .jp-card-front .jp-card-shiny,
.jp-card .jp-card-back .jp-card-shiny {
  background-image: url(/images/ui/chip.svg);
  background-color: #C6CAD3;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 0 1px rgba(140,144,164,.4);
  width: 50px;
  height: 35px;
  border-radius: 5px;
  position: absolute;
  top: 12%;
  left: 8%;
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-shiny,
  .jp-card .jp-card-back .jp-card-shiny {	
    width: 42px;
    height: 29px;
	border-radius: 3px;
  }
}
.jp-card .jp-card-front .jp-card-logo {
  position: absolute;
  opacity: 0;
  right: 8%;
  top: 12%;
  transition: 400ms
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-logo {
    transform: scale(.8);
    transform-origin: right center;
  }	
}
.jp-card .jp-card-front .jp-card-lower {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  position: absolute;
  left: 0;
  bottom: 25px
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower {
    bottom: 22px
  }
}
.jp-card .jp-card-front .jp-card-lower .jp-card-cvc {
  visibility: hidden;
  float: right;
  position: relative;
  bottom: 5px
}
.jp-card .jp-card-front .jp-card-lower .jp-card-number {
  font-size: 25.6px; /* 24px */
  clear: both;
  margin-bottom: 20px;
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    font-size: 21.2px;
	margin-bottom: 15px;
  }
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
  letter-spacing: 0em;
  font-size: 20px;
  position: relative;
  float: right;
  text-align: right;
  width: 25%;
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
    font-size: 16px;
  }	
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before,
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  font-weight: bold;
  font-size: 7px;
  white-space: pre;
  display: block;
  opacity: .5
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before {
  content: attr(data-before);
  margin-bottom: 6px;
  font-size: 8px;
  text-transform: uppercase
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  position: absolute;
  content: attr(data-after);
  text-align: right;
  right: 100%;
  margin-right: 5px;
  margin-top: 2px;
  bottom: 0;
  display: none;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-name {
  text-transform: uppercase;
  font-size: 20px;
  max-height: 45px;
  position: absolute;
  bottom: 0;
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-name {
	font-size: 16px;
  }
}
.jp-card .jp-card-back {
  transform: rotateY(180deg)
}
.jp-card .jp-card-back .jp-card-bar {
  background-color: #595C6A;
  background-image: linear-gradient(83.88deg, #595C6A 0%, #737786 100%);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%
}
.jp-card .jp-card-back:after {
  content: " ";
  display: block;
  background-color: #fff;
  background-image: linear-gradient(#fff, #fff);
  width: 70%;
  height: 16%;
  position: absolute;
  top: 40%;
  left: 8%
}

.jp-card .jp-card-back .jp-card-cvc {
  position: absolute;
  top: 40%;
  right: 8%;
  transition-delay: 600ms
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-back .jp-card-cvc {
	font-size: 12px;
  }
}
.jp-card .jp-card-back .jp-card-shiny {
  display: none;
  /*position: absolute;
  top: 66%;
  left: 2%;*/
}
.jp-card .jp-card-back .jp-card-shiny:after {
  content: "";
  position: absolute;
  left: 120%;
  top: 5%;
  color: white;
  font-size: 7px;
  width: 230px;
  opacity: .5
}
.jp-card.jp-card-identified {
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.3) */
}
.jp-card.jp-card-identified .jp-card-front,
.jp-card.jp-card-identified .jp-card-back {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5)
}
.jp-card.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-identified .jp-card-back:before {
  /* background-image: linear-gradient(-35deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); */
  background-image: url(/images/ui/card-shine.svg);
  background-size: cover;
  opacity: 1;
  transition: all 400ms ease;
}
.jp-card.jp-card-identified .jp-card-front .jp-card-logo,
.jp-card.jp-card-identified .jp-card-back .jp-card-logo {
  /* box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3); */
}
.jp-card.jp-card-identified.no-radial-gradient .jp-card-front:before,
.jp-card.jp-card-identified.no-radial-gradient .jp-card-back:before {
  /* background-image: linear-gradient(-35deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); */
  background-image: url(/images/ui/card-shine.svg);
  background-size: cover;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-number.font-size-1 {
  font-size: 21.2px;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-number.font-size-2 {
  font-size: 25.6px;
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-number.font-size-1 {
    font-size: 17.4px;
  }
}
@media only screen and (max-width: 580px) {
  .jp-card .jp-card-front .jp-card-lower .jp-card-number.font-size-2 {
    font-size: 21.1px;
  }
}