// Colors
$white-color:               #fff;
$black-color:               #203a4e;

$gray-100-color:            #F4F4F4;
$gray-200-color:            #A9ACB5;
$gray-300-color:            #72809D;

$base-color:                #DE5021;   // gold
$error-color:               #ff542b;   // red
$additional-color:          #625EF7;   // violet

