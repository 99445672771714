/* -----------------------------------------------------------------
    - News
----------------------------------------------------------------- */

.itemNews {
  flex: 0 0 46%;
  margin-bottom: 1.875rem;
  position: relative;
  width: 46%;
  
  @media only screen and (max-width: $large-xl) {
    flex: 0 0 48%;
    width: 48%;
  }  
  
  @media only screen and (max-width: $large) {
    flex: 0 0 100%;
	width: 100%;
  }  
  
  @media only screen and (max-width: $small) {
    margin-bottom: 1rem;
  }
  
  &__img-wrap {
    height: 360px;
	overflow: hidden;
	margin-bottom: 0;
	position: relative;
	
	@media only screen and (max-width: $large-xl) {
	  height: 320px;
	}
	
	@media only screen and (max-width: $large) {
	  height: 420px;
	}
	
	@media only screen and (max-width: $medium) {
	  height: 360px;
	}
	
	@media only screen and (max-width: $small) {
	  border-radius: 6px;
	  height: 300px;
	  -webkit-mask-image: -webkit-radial-gradient(white, black);
	}
  }
  
  .cover {
	transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.2,1,0.8,1);
  }  
  
  &:hover .cover {
	transform: scale(1.05);
  }  
  
  &__details {
    padding: 1.5rem 1.5rem 0;
	
	@media only screen and (max-width: $large-xl) {
	  padding: 1.5rem 1.2rem 0;
	}
	
	@media only screen and (max-width: $small) {
	  padding: 1rem 0 0;
	}
  }

  &__link {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    text-decoration: none;
    z-index: 1;
  }
  
  &__date {
    background-color: $white-color;
	color: $black-color;
	font-size: .75rem;
	font-weight: $weight-600;
	letter-spacing: .03rem;
	padding: 1.5rem 2.5rem 1.2rem 1.5rem;
	@include position(absolute, $bottom: 0, $left: 0);
	text-transform: uppercase;
	text-decoration: none;
	z-index: 1;
	
	span {
	  color: $gray-200-color;
	}
	
	@media only screen and (max-width: $large-xl) {
	  padding: 1.2rem 2.0rem 1.0rem 1.2rem;
	}
	
	@media only screen and (max-width: $small) {
	  border-top-right-radius: 6px;
	  font-size: .625rem;
	  padding: 1rem 1rem .5rem 0;
	}
  }
}


/* -----------------------------------------------------------------
    - News post
----------------------------------------------------------------- */

.post-header {
  margin-bottom: 2.5rem;
  position: relative;
  text-align: center;
  
  &::before {
    content: '';
	background-color: $black-color;
	height: 1px;
    margin: auto;
	@include position(absolute, $bottom: 0, $left: 0, $right: 0);
	width: 4rem;
  }
  
  @media only screen and (max-width: $small) {
    margin-bottom: 1.7rem;
  }
}

.post-main-image {
  height: 660px;
  margin-bottom: 3.5rem;
  
  @media only screen and (max-width: $large-xl) {
    height: 600px;
  }
  
  @media only screen and (max-width: $large) {
    height: 480px;
	margin-bottom: 2.5rem;
  }
  
  @media only screen and (max-width: $medium) {
    height: 380px;
	margin-bottom: 2.5rem;
  }
  
  @media only screen and (max-width: $small) {
    height: 300px;
	margin-bottom: 1.7rem;
	
	img {
	  border-radius: 6px;
	}
  }  
}

.post-footer {
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  position: relative;
  text-align: center;
  
  &::before {
    content: '';
	background-color: $gray-200-color;
	height: 1px;
    margin: auto;
	@include position(absolute, $left: 0, $bottom: 0, $right: 0);
	width: 4rem;
  }
  
  @media only screen and (max-width: $small) {
    margin-top: .8rem;
    margin-bottom: 2rem;
    padding-bottom: 1.3rem;
  }
}

/* Share */
.share-post {
  position: relative;
  text-align: center;
	  
  a {
    color: $black-color;
    display: inline-flex;
    margin: 0 1.5rem;
    text-decoration: none;
	
	&:hover {
	  color: $base-color;
	}
	
    @media only screen and (max-width: $small) {
      margin: 0 1rem;
    }
	
    span {
      font-size: .86rem;
	  font-weight: $weight-500;
	  line-height: 1.3;
      padding-left: .8rem;
		  
      @media only screen and (max-width: $small) {
        display: none;
	  }
    } 
  }
}