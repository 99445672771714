/* -----------------------------------------------------------------
    - About
----------------------------------------------------------------- */

.slider-about {
  @media only screen and (max-width: $small) {
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .swiper-slide {
    margin-top: 1.5rem;
	  height: auto;
	
    @media only screen and (min-width: $small) {
	  margin-top: 1.5rem;
      max-width: 510px;
    }

    @media only screen and (min-width: $medium) {
	  margin-top: 1.8rem;
	  height: 459px;
      max-width: 690px;
    }

    @media only screen and (min-width: $large) {
	    height: 620px;
      max-width: 930px;
    }

    @media only screen and (min-width: $large-xl) {
	  margin-top: 3rem;
	  height: 680px;
      max-width: 1110px;
    }
	
    @media only screen and (min-width: $large-xxl) {
	  height: 680px;
      max-width: 1210px;
    }
  }
}

/* Section approach */
.item-approach {
  border-top: 1px solid rgba($gray-200-color,.2);
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
  
  &::before {
    background-color: $white-color;
    content: attr(data-approach);
	color: $gray-300-color;
	margin-top: -.9rem;
	padding-left: 15px;
	padding-right: 1.5rem;
	@include position(absolute, $top: 0, $left: 0);
  }
  
  &::after {
    background-color: $gray-300-color;
	content: '';
    height: 1px;
	margin-top: -1px;
	padding-left: 15px;
	@include position(absolute, $top: 0, $left: 3rem);
	width: 4rem;
	z-index: -1;
  }  
  
  @media only screen and (max-width: $large-xl) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  @media only screen and (max-width: $small) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }  
  
  &__last {
    padding-bottom:  0;
  }
  
  .paragraph:last-child {
    margin-bottom: 0;
  }
}

/* Section team */
.itemPerson {
  margin-bottom: 1.875rem;
  position: relative;
  
  @media only screen and (max-width: $small) {
    margin-bottom: 1.5rem;
  }
  
  &__img-wrap {
    border-radius: 6px;
    height: 270px;
	margin-bottom: 0;
	overflow: hidden;
	position: relative;
	
    @media only screen and (max-width: $large-xxl) {
      height: 350px;
    }
  
    @media only screen and (max-width: $large-xl) {
      height: 290px;
    }
  
    @media only screen and (max-width: $large) {
      height: 330px;
    }
  
    @media only screen and (max-width: $medium) {
      height: 250px;
    }
    @media only screen and (max-width: $small) {
      height: 170px; 
    }  
  }
  
  .cover {
	transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.2,1,0.8,1);
  }  
  
  &:hover .cover {
	transform: scale(1.05);
  }

  &__details {
    padding: 1.5rem 1.5rem 0;
	
	@media only screen and (max-width: $large-xl) {
	  padding: 1.5rem 0 0;
	}
	
	@media only screen and (max-width: $small) {
	  padding: 1rem 1rem 0;
	}
  }
  
  .title {
    margin-bottom: .6rem;
  }
  
  .title:first-child {
    @media only screen and (max-width: $large-xl) {
      font-size: 1.2rem;
    }
  }
  
  .title:last-child {
    @media only screen and (max-width: $large-xl) {
      font-size: .75rem;
	  letter-spacing: .05rem;
    }
  }
}