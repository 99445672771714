/* -----------------------------------------------------------------
    - Sub Header
----------------------------------------------------------------- */

.sub-header {
  background-color: $white-color;
  border-bottom: 1px solid rgba($gray-200-color,.2);
  @include display(flex);
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  transition: all .3s ease-in-out;
  
  // IE
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
    justify-content: flex-start;
  }
  
  .form-group {
    margin-bottom: 0;
  }
  
  // Fixed size fields
  #check-in, #check-out {
  
  }
  
  #price {
    width: 120px;
  }
  
  #priceRange {
    margin-bottom: 1.5rem;
	
	@media only screen and (max-width: $small) {
	  margin-top: .6rem;
	}
  }
  
  #dropdownPriceAction {
    min-width: 224px;
  }
  
  #person-adult, #person-kids {
    min-width: 110px;
  }
}

.filters-room {
  @media only screen and (max-width: $small) {
    display: none;
  }
}

/* Mobile filters */
.bottom-nav {
  background-color: $white-color;
  box-shadow: 0 0 64px rgba($black-color,.1);
  border-radius: 18px 18px 0 0;
  height: calc(100% - 2.5rem);
  margin-top: 2.5rem;
  position: fixed;
  bottom: 0;
  z-index: 11;
  transform: translateY(calc(100% - 30px));
  transition: transform .3s ease-in-out;
  -webkit-overflow-scrolling: touch;
  
  @media only screen and (min-width: $small) {
    display: none;
  }
  
  &__control {
    @include display(flex);
    @include align-items(center);
    justify-content: center;
	cursor: pointer;
    height: 1.5rem;
    padding: 1.2rem 0;
    position: relative;
	
	&::before,
    &::after {
	  content: '';
	  background-color: #dfdfdf;
	  border-radius: 4px;
	  height: 3px;
	  position: absolute;
	  width: 1.5rem;
	  transition: transform .4s ease-in-out;
	}
	
	&::before {
	  transform: rotate(20deg) translateX(-47%);
	}
	
	&::after {
	  transform: rotate(-20deg) translateX(47%);
	}
  }
  
  &.open {
    transform: translateY(0);
	
	.bottom-nav__control::before {
	  transform: rotate(0) translateX(-47%);
	}
	
	.bottom-nav__control::after {
	  transform: rotate(0) translateX(47%);
	}
  }
  
  .container {
    height: 100%;
    overflow: auto;
	padding-bottom: 2.5rem;
  }
}