/* -----------------------------------------------------------------
    - Contact
----------------------------------------------------------------- */

.col-contact {
  margin-top: 1.5rem;
  
  @media only screen and (max-width: $medium) {
    margin-top: 1rem;
  }
  
  @media only screen and (max-width: $small) {
    margin-top: .5rem;
  }
}