/* -----------------------------------------------------------------
    - Footer
----------------------------------------------------------------- */

.footer {
  @include display(flex);
  @include align-items(center);
  justify-content: space-between;
  border-top: 1px solid rgba($gray-200-color,.2);
  margin-top: 6.8rem;
  padding: 1.6rem 3.5rem;
  
  @media only screen and (max-width: $large) {
    padding: 1.6rem 2rem;
  }
  
  @media only screen and (max-width: $medium) {
    @include flex-direction(column);
    padding: 1.2rem;
  }
  
  @media only screen and (max-width: $small) {
    margin-top: 2rem;
    padding: 1rem 15px;
	justify-content: center;
  }
  
  &__left {
    @include display(flex);
	@include align-items(center);
	flex-grow: 1;
  }
  
  .logotype {
    @media only screen and (max-width: $large) {
	  width: 142px;
	}
	
    @media only screen and (max-width: $medium) {
	  width: 120px;
	}	
  }
  
  &__info {
    font-size: 1rem;
	color: $gray-200-color;
    list-style: none;
	display: inline-flex;
	margin: 0;
	padding: 0;
	
	@media only screen and (max-width: $large) {
	  font-size: .9375rem;
	}
	
	@media only screen and (max-width: $small) {
	  @include display(flex);
	  @include flex-direction(column);
	  font-size: .875rem;
	  text-align: center;
	}	
	
	li {
	  margin-right: 2.5rem;
	  
	  &:last-child {
	    margin-right: 0;
	  }
	  
	  @media only screen and (max-width: $large) {
	    margin-right: 2rem;
	  }
	  
	  @media only screen and (max-width: $small) {
	    margin-bottom: .2rem;
	    margin-right: 0;
	  }	  
	}
	
	a {
	  color: $gray-200-color;
	  
	  &:hover {
	    color: $base-color;
	  }
	}
  }
  
  .copyright {
    padding-left: 6rem;
	position: relative;
	
	&::before {
	  content: '';
	  background-color: $black-color;
	  height: 1px;
	  margin: auto;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 1.5rem;
	  width: 3rem;
	}
	
	@media only screen and (max-width: $large) {
	  padding-left: 3rem;
	  
	  &::before {
	    left: .5rem;
        width: 1.5rem;
	  }
	}
	
	@media only screen and (max-width: $medium) {
	  display: none;
	}
  }
  
  &__social {
    list-style: none;
	margin-bottom: 0;
	line-height: 0;
	padding: 0;
	
	li {
	  display: inline-block;
	  margin-left: 1.5rem;
	  
	  &:first-child {
	    margin-left: 0;
	  }
	  
	  @media only screen and (max-width: $small) {
	    margin-left: 1rem;
	  }
	}
  }
  
  .social-link {
    font-size: 1.25rem;
	color: $black-color;
	display: block;
	line-height: 0;
	
	&:hover {
	  color: $base-color;
	}
	
	@media only screen and (max-width: $large) {
	  font-size: 1.125rem;
	}
	
	@media only screen and (max-width: $medium) {
	  margin-top: 1rem;
	}	
	
	@media only screen and (max-width: $small) {
	  margin-top: .8rem;
	}
  }
  
  &.fixed-bottom {
    @media only screen and (max-width: $small) {
	  position: relative;
	}
  }
}