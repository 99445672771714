// Input Text
%formStyle {
  background-color: $white-color;
  border: 1px solid rgba($gray-300-color,.3);
  border-radius: 6px;
  box-shadow: none;
  color: $black-color;
  line-height: 1;
  height: 48px;
  padding: 0 1.125rem;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
		  
  @media only screen and (max-width: $small) {
    font-size: .9375rem;
    height: 44px;
	padding: .1rem 1rem 0;
  }
}

%formStyle-focus {
  border: 1px solid $base-color;
  box-shadow: 0px 4px 10px rgba($base-color, .12);
  outline: none;
}

.inputText {
  @extend %formStyle;
  
  &:focus {
    @extend %formStyle-focus;
  }
  
  &__icon {
    padding: 0 1.125rem 0 3rem;
	
	@media only screen and (max-width: $small) {
	  padding: .1rem 1rem 0 2.7rem;
	}
  }
}

.inputFeature {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: $weight-600;
  line-height: 1;
  
  &:focus {
    outline: none;
  }
  
  @media only screen and (max-width: $large) {
    background-color: $white-color;
    border: 1px solid rgba($gray-300-color,.3);
    border-radius: 6px;
    color: $black-color;
	display: flex;
	font-size: 1rem;
	font-weight: $weight-400;
    line-height: 1;
    height: 48px;
    padding: 0 1.125rem;
    position: relative;
    width: 100%;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  
  @media only screen and (max-width: $small) {
    font-size: .9375rem;
    height: 44px;
	padding: 1px 1rem 0;
  }  
}

/* Label */
label, .label {
  color: $gray-300-color;
  display: block;
  font-size: .875rem;
  font-weight: $weight-500;
  margin-bottom: .4rem;
  
  @media only screen and (max-width: $small) {
    font-size: .875rem;
	margin-bottom: .3rem;
  }
}

.labelFeature {
  color: $gray-200-color;
  display: block;
  font-size: .875rem;
  font-weight: $weight-400;
  letter-spacing: .08rem;
  margin-bottom: .3rem;
  text-transform: uppercase;
  
  @-moz-document url-prefix() {
    padding-left: 1px;
  }
  
  @media only screen and (max-width: $large) {
    color: $gray-300-color;
    font-size: .875rem;
    font-weight: $weight-500;
	letter-spacing: 0;
	text-transform: none;
  }
  
  @media only screen and (max-width: $small) {
    font-size: .875rem;
	margin-bottom: .3rem;
  }  
}

/* Textarea */
.textarea {
  @extend %formStyle;
  display: block;
  line-height: 1.4;
  padding-top: .8rem;
  padding-bottom: .8rem;
  resize: none;
  
  &:focus {
    @extend %formStyle-focus;
  }
}

/* Select */
.custom-select {
  @extend %formStyle;
  background-image: url(../../icons/caret-down.svg);
  background-position: center right 1.125rem;
  background-size: .875rem;
  
  &:focus {
    @extend %formStyle-focus;
	background-image: url(../../icons/caret-up.svg);
  }
  
  &::-ms-expand {
    display: none;
  }
}

/* Checkbox */
.custom-control-input:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: $base-color;
  background-color: $base-color;
}
  
.custom-control-input:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba($base-color, 0.25);
}  

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $base-color;
}

.custom-control-label::before {
  border: 1px solid rgba($gray-300-color,.3);
}

/* Input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.js-quantity {
  position: relative;
  
  &-input {
    padding-left: 2.6rem;
	padding-right: 2.6rem;
	text-align: center;
  }
}

.qty-minus, .qty-plus {
  $size: 1.5rem;
  background-color: rgba($gray-300-color,.1);
  border-radius: 4px;
  color: $gray-300-color;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: $size;
  height: $size;
  margin: auto .75rem;
  @include position(absolute, $top: 0, $left: 0, $bottom: 0);
  width: $size;
  text-align: center;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  z-index: 1;
  
  &:hover {
    background-color: rgba($gray-300-color,.6);
	color: $white-color;
  }  
}

.qty-plus {
  left: auto;
  right: 0;
}

.form-group {
  margin-bottom: 1.2rem;
  position: relative;
  
  @media only screen and (max-width: $small) {
    margin-bottom: .8rem;
  }
}

.input-icon {
  $size: 1.125rem;
  color: rgba($gray-300-color,.5);
  font-size: $size;
  margin: auto 0;
  @include position(absolute, $top: 0, $left: 1.125rem, $bottom: 0);
  height: $size;
  width: $size;
  z-index: 1;
	
  @media only screen and (max-width: $small) {
    font-size: 1rem;
	left: 1rem;
  }
}
  
.input-arrow {
  background-image: url(../../icons/caret-down.svg);
  background-position: center right 1.125rem;
  background-size: .875rem;
  
  @media only screen and (max-width: $small) {
    background-position: center right 1rem;
    background-size: .75rem;
  }
  
  // IE
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-position: center right;
    background-size: 60%;
	
	@media only screen and (max-width: $large) {
      background-size: 56px;
    }
  }
}

/* Form Dual */
.form-dual {
  background-color: $white-color;
  border: 1px solid rgba($gray-300-color,.3);
  box-shadow: 0px 4px 10px rgba($base-color, .0);
  border-radius: 6px;
  @include display(flex);
  @include align-items(center);
  height: 48px;
  transition: all .3s ease-in-out;
  
  &:hover {
    border: 1px solid $base-color;
    box-shadow: 0px 4px 10px rgba($base-color, .12);
  }
  
  @media only screen and (max-width: $small) {
    height: 44px;
  }
  
  &__left, &__right {
    display: inline-flex;
    @include align-items(center);
	flex: 0 0 50%;
    position: relative;
	width: 50%;
  }
  
  &__left {
    border-right: 1px solid rgba($gray-300-color,.3);
  }
  
  .label {
	margin-bottom: 0;
	padding-left: 1.125rem;
	  
	@media only screen and (max-width: $small) {
	  padding-right: 1rem;
	}
  }
  
  input {
	background-color: transparent;
	border: 0;
	box-shadow: none;
  }	
	
  input:focus {
	border: 0;
	box-shadow: none;
  }
  
  #price-from {
    padding-left: 1rem;
    padding-right: .5rem;
	white-space: nowrap;
  }
  
  #price-to {
    padding-left: .5rem;
    padding-right: 1.4rem;
	white-space: nowrap;
  }
  
  // Mobile layout
  &--mobile {
    @media only screen and (max-width: $small) {
      @include flex-direction(column);
      height: auto;
	
	  .form-dual__left, .form-dual__right {
	    flex: 0 0 100%;
        width: 100%;
	  }
	
	  .form-dual__left {
	    border-right: 0;
	    border-bottom: 1px solid rgba($gray-300-color,.3);
	  }
    }
  }
}

/* Input prefix */
.input-prefix {
  position: relative;
  
  &:before {
    content:attr(data-prefix);
	color: rgba($gray-300-color,.6);
    line-height: 1.2;
	@include position(absolute, $top: 0, $left: 1rem, $bottom: 0);
	z-index: 1;
  }
  
  .inputText {
    padding-left: 1.8rem;
  }
}

/* Validation */
.validation-danger,
.validation-success {
  background-color: rgba($base-color,.1);
  padding: .5rem 1rem;
  transition: all .3s ease-in-out;
}

#validator-contact {
  border-radius: 4px;
  text-align: center;
}

.help-block {
  position: absolute;
  font-size: .75rem;
  line-height: 1.4;
  padding-top: .8rem;
}

.with-errors .list-unstyled {
  color: $base-color;
  margin-bottom: 0;
}

.has-error input,
.has-error textarea,
.has-error input:focus,
.has-error textarea:focus {
  border-color: $error-color;
}

#validator-contact {
  font-size: .89rem;
   
  @media only screen and (max-width: $medium) {
    padding-top: 1rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $white-color!important;
}

input::-webkit-input-placeholder {color: rgba($gray-300-color,.6);}
:-moz-placeholder {color: rgba($gray-300-color,.6)!important;opacity:1;}
::-moz-placeholder {color: rgba($gray-300-color,.6)!important;opacity:1;}
input:-ms-input-placeholder {color: rgba($gray-300-color,.6);}
input::-ms-input-placeholder {color: rgba($gray-300-color,.6);}
input::placeholder {color: rgba($gray-300-color,.6);}

/* Edge */
input::-ms-clear {
  display: none;
}

input:required:invalid {
  outline: none;
}